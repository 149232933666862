import { Language } from '@lightningjs/sdk'

import { ENTITLEMENT, MPARTICLE_DEFAULT_ATTR_VALUE, PROGRAMMING_TYPES } from '../../../../constants'

export const getResumeAttributes = ({ resumeTime }: any) => {
  const isResume = !!resumeTime
  return {
    Resume: isResume ? 'True' : 'False',
    'Resume Time': isResume ? resumeTime : MPARTICLE_DEFAULT_ATTR_VALUE,
  }
}

type VodAttributeParams = {
  programmingType?: any
  brandDisplayTitle?: string
  seriesShortTitle?: string
  movieShortTitle?: string
  seasonNumber?: any
  mpxGuid?: any
  pid?: any
  locked?: any
  genre?: string
  category?: any
  secondaryGenre?: string
  videoTitle?: string
  secondaryTitle?: string
  episodeNumber?: any
  sport?: string
  league?: string
  language?: string
  programTitle?: string
}
const getVodAttributes = (
  params: VodAttributeParams = {},
  ccLanguage: any,
  previousVideo: any,
  previousVideoType: any,
  analytics: any = {}
) => {
  if (!params) return {}
  const isMovie = params?.programmingType === PROGRAMMING_TYPES.MOVIE
  return {
    Brand: params.brandDisplayTitle,
    Show: params.seriesShortTitle || params.movieShortTitle || params.programTitle,
    Season: params.seasonNumber,
    'Video ID': params.mpxGuid || params.pid,
    'Video Type': params.programmingType,
    Entitlement: params.locked ? ENTITLEMENT.ENTITLED : ENTITLEMENT.FREE,
    Genre: params.genre,
    Category: params.category,
    'Secondary Genre': params.secondaryGenre,
    ...(!isMovie
      ? {
          'Episode Title': params.videoTitle || params.secondaryTitle,
          'Episode Number': params.episodeNumber,
        }
      : { 'Episode Title': params.movieShortTitle }),
    'CC Language': ccLanguage !== 'off' && ccLanguage,
    Sport: params.sport || analytics.sport || MPARTICLE_DEFAULT_ATTR_VALUE,
    League: params.league || analytics.league || MPARTICLE_DEFAULT_ATTR_VALUE,
    Language: analytics.language || params.language || Language.get(),
    'Previous Video': previousVideo,
    'Previous Video Type': previousVideoType,
    'Token Type': MPARTICLE_DEFAULT_ATTR_VALUE,
  }
}

export default getVodAttributes
