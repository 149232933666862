export const lowercaseFirst = (s: any) => {
  return s?.charAt(0).toLowerCase() + s?.slice(1)
}

export const removeHtmlParams = (str: any) => {
  return str
    .replace(/<\/ *p>|<br *\/?>/g, '\n')
    .replace(/<a *href="(.*)">(.*)<\/ *a>/g, '$2 ($1)')
    .replace(/https?:\/\//g, '')
    .replace(/(<([^>]+)>)/gi, '')
}

export const splitString = (str: any, separator: any) => {
  return str.split(separator)
}

/**
 * Takes App Version and returns a formatted App Version string.
 * @param {string} App Version
 * @return {string} Formatted App Version to 7 digits (4.2.1 -> 4002001)
 */
export const formatAppVersion = (appVersion: string): number => {
  return parseInt(
    appVersion
      .split('.')
      .map((num, i) => num.padStart(i === 0 ? 1 : 3, '0'))
      .join('')
  )
}

/**
 * Pipe separate text(s) with extra space and add space around existing pipes
 *
 * @param {string|array} texts 1 text item to add space to pipes or array of texts to pipe separate
 * @param {string} findPipe find text defaults pipe wrapped in single spaces
 * @param {string} replacePipe replace text + separator - defaults pipe wrapped w/3 spaces
 *
 * @return {string} new text with more space around pipes/pipe-concatenated (if array).
 */
export const pipeSeparate = (
  texts: string | string[],
  findPipe = ' | ',
  replacePipe = '   |   '
) =>
  !texts
    ? ''
    : typeof texts === 'string'
    ? texts.replace(findPipe, replacePipe)
    : texts?.reduce(
        (prev: any, curr: any) => prev + replacePipe + curr.replace(findPipe, replacePipe)
      )

export const removeWhiteSpace = (str: any) => str?.replace(/\s/g, '')

export const createHash = (str: string) => {
  let h, i, l
  for (h = 5381 | 0, i = 0, l = str.length | 0; i < l; i++) {
    h = (h << 5) + h + str.charCodeAt(i)
  }
  return h >>> 0
}
