export enum ROUTE {
  boot = '$',
  splash = 'splash',
  home = 'home',
  shows = 'shows',
  showsWithBrand = 'shows/:brand/:category?',
  networks = 'networks',
  settings = 'settings',
  profile = 'settings/myprofile',
  appSettings = 'settings/appsettings',
  language = 'settings/applanguage',
  closedCaptioning = 'settings/closedcaptioning',
  contactUs = 'settings/contactus',
  about = 'settings/about',
  privacy = 'settings/privacy',
  faq = 'settings/faq',
  longtext = 'longtext',
  showHome = 'show/:urlAlias',
  movieHome = 'movie/:itemName',
  search = 'search/:query?',
  video = 'video/:videoId',
  credits = 'credits/:videoId',
  networkLanding = 'networks/:machineName',
  live = 'live',
  liveWithParams = 'live/:channelId/:streamAccessName?',
  watch = 'watch/:pid',
  liveUpcoming = 'live-upcoming/:mmddyyyy?',
  replays = 'replays/:mmddyyyy?',
  sports = 'sports',
  debug = 'debug',
  episodeGuide = 'EpisodeGuide',
  notFound = '*',
  error = '!',
  olympics = 'olympics',
  paralympics = 'paralympics',
  olympicsReplays = 'oly-replays',
  olympicsHighlights = 'oly-highlights',
}

export const ROUTE_ENTITY = {
  pid: ':pid',
  videoId: ':videoId',
  machineName: ':machineName',
  urlAlias: ':urlAlias',
  itemName: ':itemName',
}
