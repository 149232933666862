export enum LANGUAGES {
  SPANISH = 'es',
  DEFAULT = 'en',
}

/**
 * @typedef {{
 *  name: string,
 *  app: string,
 *  brand: string | undefined,
 *  analytics: string,
 *  nielsen: string,
 *  language: 'es' | 'en',
 *  cmpName: string | undefined,
 *  bundleId: string
 * }} WhiteLabelBrand
 */

export interface IWhiteLabelBrand {
  name: string
  app: string
  analytics: string
  nielsen: string
  language: LANGUAGES
  bundleId: string
  liveCallSign: CALL_SIGNS
  cmpName?: string
  configName?: string
}

export enum BRAND {
  bravo = 'bravo',
  cnbc = 'cnbc',
  deportes = 'deportes',
  e = 'e',
  golf = 'golf',
  msnbc = 'msnbc',
  nbc = 'nbc',
  news = 'news',
  nbcsports = 'nbcsports',
  oxygen = 'oxygen',
  syfy = 'syfy',
  telemundo = 'telemundo',
  mun2 = 'mun2',
  usa = 'usa',
}

type BrandList = {
  [key in BRAND]: IWhiteLabelBrand
}

export enum CALL_SIGNS {
  nbcnews = 'NBCNN',
  nbc = 'KNTV',
  bravo_east = 'BRAVOHD',
  bravo_west = 'BRAVOHP',
  cnbc = 'CHDSTR',
  e_east = 'EHD',
  e_west = 'EPHD',
  golf = 'GOLFHDB',
  msnbc = 'MNBCHD',
  oxygen_east = 'OXYGNHD',
  oxygen_west = 'OXYGNHP',
  syfy_east = 'SYFYHD',
  syfy_west = 'SYFYHDP',
  universo_east = 'UNVSOHD',
  universo_west = 'UVSPHST',
  usa_east = 'USAHD',
  usa_west = 'USAHDP',
  necn = 'NECNHD',
  nbcsbayarea = 'NBCSBAH',
  nbcsboston = 'NBCSBH',
  nbcscalifornia = 'NBSCAHD',
  nbcschicago = 'NBCSCHD',
  nbcsphiladelphia = 'NBCSPAH',
  nbcsportspeacock = 'PK22',
}

export const WHITE_LABEL_BRANDS: BrandList = {
  [BRAND.bravo]: {
    name: 'bravo',
    app: 'Bravo App',
    analytics: 'Bravo',
    nielsen: 'Bravo One App',
    language: LANGUAGES.DEFAULT,
    cmpName: 'Bravo',
    bundleId: 'com.nbcuni.bravo.xclass',
    liveCallSign: CALL_SIGNS.bravo_east,
  },
  [BRAND.cnbc]: {
    name: 'cnbc',
    app: 'CNBC App',
    analytics: 'CNBC',
    nielsen: 'CNBC',
    language: LANGUAGES.DEFAULT,
    bundleId: 'com.nbcuni.cnbc.xclass',
    liveCallSign: CALL_SIGNS.cnbc,
  },
  [BRAND.deportes]: {
    name: 'deportes',
    app: 'Deportes App',
    analytics: 'Deportes',
    nielsen: 'Deportes',
    language: LANGUAGES.DEFAULT,
    bundleId: 'com.nbcuni.deportes.xclass',
    liveCallSign: CALL_SIGNS.nbcnews,
  },
  [BRAND.e]: {
    name: 'e',
    app: 'E! App',
    analytics: 'E!',
    nielsen: 'E! One App',
    language: LANGUAGES.DEFAULT,
    cmpName: 'E!_Entertainment',
    bundleId: 'com.nbcuni.e.xclass',
    liveCallSign: CALL_SIGNS.e_east,
  },
  [BRAND.golf]: {
    name: 'golf',
    app: 'Golf App',
    analytics: 'Golf',
    nielsen: 'Golf',
    language: LANGUAGES.DEFAULT,
    bundleId: 'com.nbcuni.golf.xclass',
    liveCallSign: CALL_SIGNS.golf,
  },
  [BRAND.msnbc]: {
    name: 'msnbc',
    app: 'MSNBC App',
    analytics: 'MSNBC',
    nielsen: 'NBC News',
    language: LANGUAGES.DEFAULT,
    cmpName: 'MSNBC',
    bundleId: 'com.nbcuni.msnbc.xclass',
    liveCallSign: CALL_SIGNS.msnbc,
  },
  [BRAND.nbc]: {
    name: 'nbc',
    app: 'NBC App',
    analytics: 'NBC',
    nielsen: 'NBC Network App',
    language: LANGUAGES.DEFAULT,
    cmpName: 'NBC_Entertainment',
    bundleId: 'com.nbcuni.nbc.xclass',
    liveCallSign: CALL_SIGNS.nbc,
  },
  [BRAND.news]: {
    name: 'news',
    app: 'NBC News App',
    analytics: 'NBC News',
    nielsen: 'NBC News',
    language: LANGUAGES.DEFAULT,
    bundleId: 'com.nbcuni.news.xclass',
    liveCallSign: CALL_SIGNS.nbcnews,
  },
  [BRAND.nbcsports]: {
    configName: 'nbcSports',
    name: 'nbcsports',
    app: 'NBC Sports App',
    analytics: 'NBC Sports',
    nielsen: 'NBC Sports app',
    language: LANGUAGES.DEFAULT,
    cmpName: 'NBCSportsGroupOlympics',
    bundleId: 'com.nbcuni.nbcsports.xclass',
    liveCallSign: CALL_SIGNS.nbcsportspeacock,
  },
  [BRAND.oxygen]: {
    name: 'oxygen',
    app: 'Oxygen App',
    analytics: 'Oxygen',
    nielsen: 'Oxygen One App',
    language: LANGUAGES.DEFAULT,
    cmpName: 'Oxygen_Media',
    bundleId: 'com.nbcuni.oxygen.xclass',
    liveCallSign: CALL_SIGNS.oxygen_east,
  },
  [BRAND.syfy]: {
    name: 'syfy',
    app: 'SYFY App',
    analytics: 'Syfy',
    nielsen: 'Syfy One App',
    language: LANGUAGES.DEFAULT,
    cmpName: 'SyFy',
    bundleId: 'com.nbcuni.syfy.xclass',
    liveCallSign: CALL_SIGNS.syfy_east,
  },
  [BRAND.telemundo]: {
    name: 'telemundo',
    app: 'Telemundo App',
    analytics: 'Telemundo',
    nielsen: 'Telemundo One App',
    language: LANGUAGES.SPANISH,
    cmpName: 'Telemundo_Networks',
    bundleId: 'com.nbcuni.telemundo.xclass',
    liveCallSign: CALL_SIGNS.nbcnews,
  },
  [BRAND.mun2]: {
    name: 'mun2',
    app: 'Universo App',
    analytics: 'Universo',
    nielsen: 'Universo One App',
    language: LANGUAGES.DEFAULT,
    cmpName: 'Universo',
    bundleId: 'com.nbcuni.mun2.xclass',
    liveCallSign: CALL_SIGNS.universo_east,
  },
  [BRAND.usa]: {
    name: 'usa',
    app: 'USA App',
    analytics: 'USA',
    nielsen: 'USA One App',
    language: LANGUAGES.DEFAULT,
    cmpName: 'USA_Network',
    bundleId: 'com.nbcuni.usanetwork.xclass',
    liveCallSign: CALL_SIGNS.usa_east,
  },
}

export const CLIENT_NAME = 'oneapp'
export const WEB_PLATFORM = 'web'

export enum PROGRAMMING_TYPES {
  NONE = 'None',
  FULL_EPISODE = 'Full Episode',
  MOVIE = 'Movie',
  CLIP = 'Clip',
  HIGHLIGHT = 'Highlight',
  HIGHLIGHTS = 'Highlights',
  SFVOD = 'Short-Form VOD',
  SLE = 'Single Live Event',
  FER = 'Full Event Replay',
  EXCERPT = 'Excerpt',
  WEB_EXCLUSIVE = 'Web Exclusive',
  SNEAK_PEAK = 'Sneak Peek',
  TEASER_TRAILER = 'Teaser Trailer',
  OLYMPICS = 'Olympics',
  TRAILER = 'Trailer',
  PREVIEW = 'Preview',
  CURRENT_PREVIEW = 'Current Preview',
  RECAP = 'Recap',
}

export const VOD_PROGRAMMING_TYPES = new Set([
  PROGRAMMING_TYPES.FULL_EPISODE,
  PROGRAMMING_TYPES.MOVIE,
  PROGRAMMING_TYPES.CLIP,
  PROGRAMMING_TYPES.HIGHLIGHT,
  PROGRAMMING_TYPES.HIGHLIGHTS,
  PROGRAMMING_TYPES.SFVOD,
  PROGRAMMING_TYPES.EXCERPT,
  PROGRAMMING_TYPES.WEB_EXCLUSIVE,
  PROGRAMMING_TYPES.SNEAK_PEAK,
  PROGRAMMING_TYPES.TEASER_TRAILER,
])

export const CLIP_PROGRAMMING_TYPES: Set<PROGRAMMING_TYPES> = new Set([
  PROGRAMMING_TYPES.CLIP,
  PROGRAMMING_TYPES.EXCERPT,
  PROGRAMMING_TYPES.HIGHLIGHT,
  PROGRAMMING_TYPES.HIGHLIGHTS,
  PROGRAMMING_TYPES.TEASER_TRAILER,
  PROGRAMMING_TYPES.SNEAK_PEAK,
  PROGRAMMING_TYPES.TRAILER,
  PROGRAMMING_TYPES.WEB_EXCLUSIVE,
  PROGRAMMING_TYPES.PREVIEW,
  PROGRAMMING_TYPES.CURRENT_PREVIEW,
  PROGRAMMING_TYPES.RECAP,
])

export const XY_FALLBACK_TYPES = {
  x: 'X',
  y: 'Y',
  none: 'None',
}

export enum STORAGE_KEYS {
  MPARTICLE = 'mpid',
  PREVIOUS_STREAM = 'PREVIOUS_STREAM',
  PREVIOUS_CHANNEL = 'PREVIOUS_CHANNEL',
  PREVIOUS_CALLSIGN = 'PREVIOUS_CALLSIGN',
  REFERRING_SHELF = 'REFERRING_SHELF',
  PLAYLIST_MACHINE_NAME = 'PLAYLIST_MACHINE_NAME',
  USER_OPT_OUT = 'USER_OPT_OUT',
  APP_LOADED_TIME = 'APP_LOADED_TIME',
  SMART_TILE = 'SMART_TILE',
  APP_LAUNCH_TYPE = 'APP_LAUNCH_TYPE',
  APP_LANGUAGE = 'appLanguage',
  IS_NOT_DAY_ZERO = 'isNotDayZero',
  PREFERRED_AUDIO_LANG = 'preferredAudioLang',
  ONBOARDING_WAS_SHOWN = 'onboardingWasShown',
  CMP_STORAGE_KEYS = 'CMP_STORAGE_KEYS',
  CMP_CONSENT_STRING = 'CMP_CONSENT_STRING',
}

export enum LegacyStoreKey {
  deviceId = 'app.deviceID',
  ccOptions = 'bhm.cc.options',
  accessToken = 'nbcProfile.accessToken',
  language = 'i18n.lan',
}

export enum ENTITY_TYPES {
  VIDEO = 'video',
  REPLAY = 'replay',
  VIDEOS = 'videos',
  SERIES = 'series',
  MOVIE = 'movie',
  ON_AIR = 'onAir',
  SLIDE = 'slide',
  UPCOMING_LIVE_TILE = 'upcomingLiveTile',
  UPCOMING_LIVE_SLIDE = 'upcomingLiveSlide',
  UPCOMING_LIVE_SLIDE_TILE = 'upcomingLiveSlideTile',
  BRAND = 'brand',
  SHOWS = 'shows',
  SMART_TILES = 'smartTiles',
  CTA_SMART_TILES = 'ctaSmartTiles',
  SEARCH_RESULTS = 'searchResults',
  RECOMMENDED = 'recommended',
  NETWORK = 'network',
  STREAM = 'stream',
  PLAYLIST = 'playlist',
  LIVE_TO_VOD = 'liveToVod',
  MARKETING_MODULE = 'marketingModule',
}

export enum REGISTRATION_REFERRERS {
  VOD = 'VOD Asset',
  LIVE = 'Live Stream',
  MVPD = 'MVPD Login',
  TEMP_PASS = 'Temp Pass',
}

export enum CONTENT_TYPE {
  LINEAR = 'LINEAR',
  PLAYMAKER = 'PLAYMAKER',
  SLE = 'SLE',
  FER = 'FER',
  VOD = 'VOD',
}

export enum MENU_ITEM_TYPE {
  DIVIDER = 'divider',
  ITEM = 'item',
}

export enum USER_OPT_OUT_PREFERENCE {
  ALLOW_SALE = 0,
  DISALLOW_SALE = 1,
}

export enum ENTITLEMENT {
  ENTITLED = 'Entitled',
  FREE = 'Free',
}

export enum SLE_STATUS {
  EXPIRED = 'expired',
  LIVE = 'live',
  UPCOMING = 'upcoming',
}

export enum LINEAR_CHANNEL_TYPE {
  SPECIALITY = 'speciality-channel',
}

export enum COMPONENT_TYPES {
  SLIDE_SHOW = 'Slideshow',
  SHELF = 'Shelf',
  ON_AIR_NOW_SHELF = 'OnAirNowShelf',
  LAZY_SHELF = 'LazyShelf',
  BRAND_SELECTABLE_GROUP = 'BrandSelectableGroup',
  LINK_SELECTABLE_GROUP = 'LinksSelectableGroup',
  LAZY_LINK_SELECTABLE_GROUP = 'LazyLinksSelectableGroup',
  LAZY_ON_AIR_NOW_SHELF = 'LazyOnAirNowShelf',
  GRID = 'Grid',
  SMART_TILE = 'SmartTile',
  END_TILES = 'EndTiles',
  GUIDE = 'Guide',
  GUIDE_SCHEDULE = 'GuideSchedule',
  EVENT_SCHEDULE = 'EventSchedule',
  SLIDE = 'Slide',
  UPCOMING_LIVE_SLIDE = 'UpcomingLiveSlide',
  UPCOMING_LIVE_TILE = 'UpcomingLiveTile',
  SPORTS_LIVE_GUIDE_TILE = 'SportsLiveGuideTile',
  MESSAGE = 'Message',
  REPLAY_TILE = 'ReplayTile',
  GROUPED_CONTINUOUS_SCROLL = 'GroupedContinuousScroll',
  CONTINUOUS_SCROLL = 'ContinuousScroll',
  PLACEHOLDER = 'PlaceholderSection',
  PLACEHOLDER_ELEMENT = 'Placeholder',
  MARKETING_MODULE = 'MarketingModule',
  VIDEO_TILE = 'VideoTile',
  END_CARD_ALTERNATE = 'EndCardAlternate',
  END_CARD_ALTERNATE_SLE = 'EndCardAlternateSLE',
  NESTED_LINK_SELECTABLE_GROUP = 'NestedLinksSelectableGroup',
  STACK = 'Stack',
  STACK_GROUP = 'StackGroup',
}

export const END_CARD_TYPES = {
  [PROGRAMMING_TYPES.FULL_EPISODE]: 'episode',
  [PROGRAMMING_TYPES.MOVIE]: 'movieFullVideo',
  [PROGRAMMING_TYPES.SFVOD]: 'sportsVideo',
  [PROGRAMMING_TYPES.SLE]: 'singleLiveEvents',
  DEFAULT: 'segment',
} as const

export const MAX_TIME_OUT = 2880000 // 2 DAYS

export const MAX_HOURS_PASSED = 24

export const PLACEHOLDER_TIMEOUT = 4000 // 4 SECONDS

export const SINGLE_LIVE_EVENT = 'Single Live Event'

export const svgRegExp = /\.(svg)/

export const httpsRegExp = /^(?:https?:)?\/\//i

export const FALLBACK_TIMEZONE = 'America/New_York' // EST -05:00

export const SHOWS_SHELF_TITLE = 'Shows'

export const SHIMMER_LOADER_TILES = 10

export const AD_TYPES = {
  PREROLL: 'Preroll',
  MIDROLL: 'Midroll',
}

export enum DESTINATION_TYPES {
  video = 'video',
  series = 'series',
  internalPage = 'internalPage',
  playlist = 'playlist',
  singleEvent = 'singleEvent',
}

export enum AUTH_REDIRECT {
  redirect = 'redirect',
}

export const END_OF_ROW = 'End of row'

export enum TRICK_PLAY_ACTIONS {
  pause = 'Content Paused',
  play = 'Playing Content',
  pauseSeekable = 'Content paused at',
  seconds_out = 'seconds out of',
  playSeekable = 'Playing at',
  fast_forward = 'Fast forwarding at',
  rewind = 'Rewiding at',
}

export enum TRICK_PLAY_ELEMENTS {
  playButton = 'Play',
  pauseButton = 'Pause',
}

export enum TIME_TTS {
  hours = 'hours',
  minutes = 'minutes',
  seconds = 'second',
}

export enum EPG_VIEWS {
  channels = 'channels',
  filters = 'filters',
}

export const STREAM_ON_PEACOCK = 'STREAM ON PEACOCK'

export const ADOBE_PATH_GEO_ERROR =
  'You do not have full subscriptions to view the requested content. Please upgrade your programming then log back in to view content.'

export const AUDIO_DESCRIPTION_TAG = 'ad'

export const LIVE_PLAYER_TAG = 'Live Player'

export const UNAUNTHENTICATED = 'Unauthenticated'
