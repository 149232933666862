export enum COLORS {
  black = 0xff000000,
  black1 = 0x4d000000,
  black2 = 0xd9000000,
  black3 = 0x40000000,
  black4 = 0x80000000,
  black5 = 0xcc000000,
  black6 = 0xa6000000,
  black7 = 0xb3000000,
  black8 = 0xaa000000,
  black9 = 0x33000000,
  black10 = 0x66000000,
  cyan = 0xff00ffff,
  onyx = 0xf2141414,
  white = 0xffffffff,
  white1 = 0xfffefefe,
  white2 = 0xfffcfcfc,
  white3 = 0xfff1f1f1,
  white4 = 0xdeffffff,
  white5 = 0xffe7e7e7,
  white6 = 0xffdddedf,
  white7 = 0x1effffff,
  dark = 0xff1f2127,
  dark2 = 0xff222222,
  dark3 = 0x1affffff,
  dark4 = 0x992b2b2b,
  dark5 = 0x12ffffff,
  dark6 = 0x4dffffff,
  dark7 = 0xff35373d,
  dark8 = 0x80a3a5a9,
  dark9 = 0x33ffffff,
  dark10 = 0xff6b7992,
  lightGray = 0xccffffff,
  lightGray1 = 0xffefefef,
  lightGray2 = 0xbfffffff,
  lightGray3 = 0xfff7f7f7,
  lightGray4 = 0x99ffffff,
  lightGray5 = 0xff979797,
  lightGray6 = 0xdeffffff,
  lightGray7 = 0xff626367,
  lightGray8 = 0xb3f7f7f7,
  lightGray9 = 0xcceeeeee,
  lightGray10 = 0xff6b7992,
  lightGray11 = 0xffd2d3d4,
  lightGray12 = 0xffa19d9d,
  magenta = 0xffff00ff,
  mediumGray = 0xff808080,
  mediumGray1 = 0xb3ffffff,
  mediumGray2 = 0xff606267,
  mediumGray3 = 0x80ffffff,
  mediumGray4 = 0x70ffffff,
  mediumGray5 = 0x50ffffff,
  mediumGray6 = 0xff4c4d52,
  warmGray = 0xff8c8c8c,
  warmGray1 = 0xffd6d6d6,
  warmGray2 = 0xb2ffffff,
  warmGray3 = 0xffe4e4e4,
  warmGray4 = 0xffababab,
  charcoalGrey = 0x40ffffff,
  thunder = 0x30cccccc,
  thunder1 = 0xff282a30,
  thunder2 = 0xff22252e,
  thunder3 = 0xff2d282d,
  brightGrey = 0xff414956,
  blue = 0xff00b2eb,
  blue1 = 0xff0000ff,
  blue2 = 0xff4078d0,
  red = 0xffd5260b,
  red1 = 0xffff0000,
  red2 = 0xffed2c0d,
  yellow = 0xffffff00,
  yellow2 = 0xffb4a074,
  purple = 0xff800080,
  green = 0xff008000,
  teal = 0xff008080,
  transparent = 0x00000000,
  menuLightGrey = 0x96000000,
  menuLightGrey1 = 0x2d000000,
  menuLightGrey2 = 0x09000000,
}

export enum FONT_FACE {
  light = 'Light',
  regular = 'Regular',
  semiBold = 'SemiBold',
  bold = 'Bold',
  roboto = 'Roboto',
  coronet = 'Coronet',
  courier = 'Courier',
  impress = 'Impress',
  timesNewRoman = 'TimesNewRoman',
  helvetica = 'Helvetica',
  arial = 'Arial',
  copperplate = 'Copperplate',
}

export enum FLEX_DIRECTION {
  row = 'row',
  column = 'column',
}

export enum JUSTIFY_CONTENT {
  center = 'center',
  flexEnd = 'flex-end',
  flexStart = 'flex-start',
  spaceAround = 'space-around',
}

export enum ALIGN_CONTENT {
  center = 'center',
  flexEnd = 'flex-end',
}
export enum ALIGN_ITEMS {
  center = 'center',
  flexStart = ' flex-start',
  flexEnd = 'flex-end',
  stretch = 'stretch',
  baseline = 'baseline',
}

export enum VERTICAL_ALIGN {
  middle = 'middle',
}

export enum TEXT_ALIGN {
  center = 'center',
}

export enum ALIGN_SELF {
  center = 'center',
  flexEnd = 'flex-end',
  flexStart = 'flex-start',
  stretch = 'stretch',
}

export const MAX_LINES_SUFFIX = '...'

export const BADGE_LABELS = ['NEW', 'PREMIERE', 'EXPIRING']

export const END_CARD_AUTOPLAY_TIME = 5
export const END_CARD_SLE_AUTOPLAY_TIME = 15
export const END_CARD_FER_AUTOPLAY_TIME = 15
export const END_CARD_FER_TRIGGER_TIME = 35
export const BASE_FPS = 54

export enum SCREEN_SIZE {
  width = 1920,
  height = 1080,
}

export const EPG_SLOT_WIDTH = 558
export const EPG_SLOT_PADDING = 4

export const WORD_WRAP_WIDTH = 510

export enum PLAYER_SIZE {
  FULL = 'FULL',
  SECONDARY = 'SECONDARY',
}

export const FULL_SCHEDULE: string[] = ['full schedule', 'calendario completo']

export const DEFAULT_STAGE_STYLES = `
          @media all {
            html {
              height: 100%; width: 100%;
            } 
            *,body {
              margin:0; 
              padding:0;
            } 
            canvas { 
              position: 
              absolute; 
              z-index: 2; 
            } body { 
              width: 100%; 
              height: 100%; 
              background-color: #000000; 
              overflow: hidden;
            }
          }`
