import { Language, Registry } from '@lightningjs/sdk'
import { ACTIVATION_REFERRER, ACTIVATION_TYPE } from './constants'
import Router from '@lightningjs/sdk/src/Router'
import { EVENTS } from '../../../lib/analytics/types'
import { PROGRAMMING_TYPES, REGISTRATION_REFERRERS } from '../../../constants'
import { ModalTypes } from '../../../lib/ModalManager'
import { getUserProfile } from '../../../api/Identity'
import { sendMetric } from '../../../lib/analytics/Analytics'
import { getCurrentModalInstance } from '..'
import { NOTIFICATION_TIMEOUT } from './strategies/ActivationStrategy'

const referrerMap = (
  programmingType: any
): Record<ACTIVATION_REFERRER, REGISTRATION_REFERRERS> => ({
  [ACTIVATION_REFERRER.LINEAR]: REGISTRATION_REFERRERS.LIVE,
  [ACTIVATION_REFERRER.TEMPPASS]: REGISTRATION_REFERRERS.TEMP_PASS,
  [ACTIVATION_REFERRER.VOD]: REGISTRATION_REFERRERS.VOD,
  [ACTIVATION_REFERRER.EVENT]:
    programmingType === PROGRAMMING_TYPES.FER
      ? REGISTRATION_REFERRERS.MVPD
      : REGISTRATION_REFERRERS.LIVE,
})

const dispatchActivationAnalytics = (activationType: ACTIVATION_TYPE) => {
  const name =
    activationType === ACTIVATION_TYPE.NBC ? EVENTS.AUTH_SUCCESS_NBC : EVENTS.AUTH_SUCCESS_MVPD
  const metadata: Record<string, any> = {
    path: ModalTypes.ACTIVATION,
    userProfile: getUserProfile(),
  }

  const context = getCurrentModalInstance()
  if (context) {
    const id = context._streamId
    const program = context._program
    const referrerType = context._referrerType as ACTIVATION_REFERRER

    if (id) {
      if (referrerType === ACTIVATION_REFERRER.VOD) metadata.videoId = id
      else metadata.pid = id
    }
    metadata.registrationReferrers =
      referrerMap(program?.programmingType)[referrerType] || REGISTRATION_REFERRERS.LIVE
  }

  sendMetric(name, metadata)
}

export const notifyUser = async (activationType: ACTIVATION_TYPE) => {
  const page = Router.getActivePage()
  const notificationWidget = page?.fireAncestors('$getWidget', 'Notification')
  if (notificationWidget) {
    notificationWidget._setNotificationMsg(
      'images/check.png',
      true,
      Language.translate(
        activationType === ACTIVATION_TYPE.MVPD ? 'tv_provider_linked' : 'nbc_profile_signed_in'
      )
    )
    notificationWidget.visible = true
    // begin timer to hide, at the call callback function if provided
    const timeout = Registry.setTimeout(() => {
      if (timeout) Registry.clearTimeout(timeout)
      notificationWidget.visible = false
      dispatchActivationAnalytics(activationType)
      Promise.resolve()
    }, NOTIFICATION_TIMEOUT + 500)
  }
}
