/**
 * Typescript compiler with ES5 has a long term issue never solved while tryign to access super instance from a getter/setter
 * https://github.com/microsoft/TypeScript/issues/338#issuecomment-334449855
 * This superGet/superSet does the trick
 * TODO: Ideally it should be injected by the transformer itself
 */
Object.superGet = (superPrototype, name, instance) => {
  do {
    const descriptor = Object.getOwnPropertyDescriptor(superPrototype, name)
    if (descriptor) {
      return descriptor.get ? descriptor.get.call(instance) : descriptor.value
    }
    // eslint-disable-next-line no-cond-assign
  } while ((superPrototype = Object.getPrototypeOf(superPrototype)))
}

Object.superSet = (superPrototype, name, instance, value) => {
  do {
    const descriptor = Object.getOwnPropertyDescriptor(superPrototype, name)
    if (descriptor) {
      return descriptor.set && descriptor.set.call(instance, value)
    }
    // eslint-disable-next-line no-cond-assign
  } while ((superPrototype = Object.getPrototypeOf(superPrototype)))
  Object.defineProperty(instance, name, {
    configurable: true,
    enumerable: true,
    value,
    writable: true,
  })
}
