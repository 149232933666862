import { Subject } from 'rxjs'
import TVPlatform from '../tv-platform'
import { ErrorType } from '../tv-platform/types'
import { CMPBannerUiResponse } from './types/bannerUi'
import { CMPPreferenceResponse } from './types/preference'
import { getBannerPreferenceHeaders, getSaveApiHeaders } from './headers'
import { Storage } from '@lightningjs/sdk'
import { STORAGE_KEYS } from '../../constants'
import AppConfigFactorySingleton from '../../config/AppConfigFactory'
import { CMPSaveResponse } from './types/save'

enum CMPEventType {
  BANNER = 'banner_ui',
  PREFERENCE = 'preference',
  SAVE = 'save',
}

type CMPEvent = {
  type: CMPEventType
  response: CMPBannerUiResponse | CMPPreferenceResponse
}

export enum CMPInteractionType {
  BANNER_ALLOW_ALL = 'BANNER_ALLOW_ALL',
  BANNER_REJECT_ALL = 'BANNER_REJECT_ALL',
  BANNER_CLOSE = 'BANNER_CLOSE',
  BANNER_CONTINUE_WITHOUT_ACCEPTING = 'BANNER_CONTINUE_WITHOUT_ACCEPTING',
  PREFERENCE_CENTER_ALLOW_ALL = 'PREFERENCE_CENTER_ALLOW_ALL',
  PREFERENCE_CENTER_REJECT_ALL = 'PREFERENCE_CENTER_REJECT_ALL',
  PREFERENCE_CENTER_CONFIRM = 'PREFERENCE_CENTER_CONFIRM',
  PREFERENCE_CENTER_CLOSE = 'PREFERENCE_CENTER_CLOSE',
  VENDOR_LIST_ALLOW_ALL = 'VENDOR_LIST_ALLOW_ALL',
  VENDOR_LIST_REJECT_ALL = 'VENDOR_LIST_REJECT_ALL',
  VENDOR_LIST_CONFIRM = 'VENDOR_LIST_CONFIRM',
  SDK_LIST_ALLOW_ALL = 'SDK_LIST_ALLOW_ALL',
  SDK_LIST_REJECT_ALL = 'SDK_LIST_REJECT_ALL',
  SDK_LIST_CONFIRM = 'SDK_LIST_CONFIRM',
  SYNC_PROFILE = 'SYNC_PROFILE',
}

export type CMPSaveApiBody = {
  interactionType: CMPInteractionType
  consent?: Partial<{
    purposesStatus: {
      groupId: string
      status: boolean
      liStatus: boolean
    }[]
    iabVendorsStatus: {
      vId: string
      status: boolean
      liStatus: boolean
    }[]
    googleVendorsStatus: {
      vId: string
      status: boolean
      liStatus: boolean
    }[]
    sdkStatus: {
      sdkId: string
      status: boolean
    }[]
  }>
  userAgent?: string
}

class ConsentManagementPlatform {
  _subject = new Subject<CMPEvent>()

  get storageKeys() {
    return Storage.get(STORAGE_KEYS.CMP_STORAGE_KEYS) || {}
  }

  set storageKeys(v) {
    Storage.set(STORAGE_KEYS.CMP_STORAGE_KEYS, v)
  }

  get consentString() {
    return Storage.get(STORAGE_KEYS.CMP_CONSENT_STRING) || ''
  }

  set consentString(v) {
    Storage.set(STORAGE_KEYS.CMP_STORAGE_KEYS, v)
  }

  async sync() {
    try {
      await Promise.all([this._fetchBannerUi(), this._fetchPreferenceCenter()])
    } catch (e) {
      TVPlatform.reportError({
        type: ErrorType.NETWORK,
        description: 'Failure loading OneTrust API',
        payload: e,
      })
      // fail silently
    }
  }

  subscribe(cb: (e: CMPEvent) => void) {
    return this._subject.subscribe(cb)
  }

  async save(body: CMPSaveApiBody) {
    try {
      if (!AppConfigFactorySingleton.config.one_trust_id) throw new Error('CMP:: undefined app id')
      const response = await fetch(AppConfigFactorySingleton.config.one_trust_save_endpoint, {
        method: 'POST',
        headers: getSaveApiHeaders(),
        body: JSON.stringify(body),
      })
      const json = await response.json()
      this.storageKeys = json.storageKeys
      this.consentString = json.otConsentString
      return json as CMPSaveResponse
    } catch (e) {
      this.storageKeys = {}
      this.consentString = ''
      TVPlatform.reportError({
        type: ErrorType.NETWORK,
        payload: e,
        description: 'Error saving CMP Save and Log consent data',
      })
      throw e
    }
  }

  async _fetchBannerUi() {
    try {
      if (!AppConfigFactorySingleton.config.one_trust_id) throw new Error('CMP:: undefined app id')
      const response = await fetch(AppConfigFactorySingleton.config.one_trust_banner_endpoint, {
        headers: getBannerPreferenceHeaders(this.consentString),
      })
      const json = await response.json()
      this.storageKeys = json.storageKeys
      this.consentString = json.otConsentString
      this._subject.next(json)
      // TODO adjust types to actual response (type is modeled from docs data)
      return json as CMPBannerUiResponse
    } catch (e) {
      this.storageKeys = {}
      this.consentString = ''
      TVPlatform.reportError({
        type: ErrorType.NETWORK,
        payload: e,
        description: 'Error requesting CMP BannerUI API',
      })
      throw e
    }
  }

  async _fetchPreferenceCenter() {
    try {
      if (!AppConfigFactorySingleton.config.one_trust_id) throw new Error('CMP:: undefined app id')
      const response = await fetch(AppConfigFactorySingleton.config.one_trust_preference_endpoint, {
        headers: getBannerPreferenceHeaders(this.consentString),
      })
      const json = await response.json()
      this.storageKeys = json.storageKeys
      this.consentString = json.otConsentString
      this._subject.next(json)
      // TODO adjust types to actual response (type is modeled from docs data)
      return json as CMPPreferenceResponse
    } catch (e) {
      this.storageKeys = {}
      this.consentString = ''
      TVPlatform.reportError({
        type: ErrorType.NETWORK,
        payload: e,
        description: 'Error requesting CMP Preference Center API',
      })
      throw e
    }
  }
}

export const ConsentManagement = new ConsentManagementPlatform()
