import { Language, Lightning, Router, Storage } from '@lightningjs/sdk'
import { Track } from '@sky-uk-ott/core-video-sdk-js-core'

import PlayerCaptions from '../../components/player/PlayerCaptions'
import PlayerQOSPanel from '../../components/player/PlayerQOSPanel'
import MediaOptionsPanel from '../../components/player/MediaOptionsPanel'
import { PlayerError } from '../../components/error/PlayerError'

import { createHash, setSmooth, templateDeepMerge } from '../../helpers'
import { StorageFactorySingleton } from '../../util/storage/StorageFactory'
import { PLAYER_TYPE, PlayerFactorySingleton } from '../../player/core/PlayerFactory'

import AppConfigFactorySingleton from '../../config/AppConfigFactory'
import { PlayerInactiveStateFactory } from './states/Inactive'
import { CorePlayerStateFactory } from './states/PlayerCore'
import { DebugControllerSingleton } from '../../util/debug/DebugController'
import {
  AdBreakEndEvent,
  AdBreakStartEvent,
  AdEndEvent,
  AdStartEvent,
  AudioTrackEvent,
  AudioTracksEvent,
  BufferEndEvent,
  BufferStartEvent,
  FatalErrorEvent,
  PlayerStatusEvent,
  SeekEndEvent,
  SeekStartEvent,
  SubtitleEvent,
  TimeChangeEvent,
} from '../../player/model/event'
import { PlayerStatus } from '../../player/model/PlayerStatus'
import {
  COLORS,
  CONTENT_TYPE,
  FONT_FACE,
  PAGE_NAME,
  PlayerStates,
  ROUTE,
  SCREEN_SIZE,
  STORAGE_KEYS,
  TEXT_ALIGN,
  VERTICAL_ALIGN,
} from '../../constants'
import PlayerStoreSingleton from '../../store/PlayerStore/PlayerStore'
import { clearState } from '../../store/PlayerStore/actions'
import { CCStyleEvent, PlatformSubscriptionType } from '../../lib/tv-platform/base'
import { syncCcSettings } from '../../lib/ClosedCaptions/ClosedCaptionsUtils'
import ModalButton from '../../components/buttons/ModalButton'
import { RouterPage } from '../../../types/global'
import { Subscription } from 'rxjs'
import LaunchDarklyFeatureFlags from '../../lib/launchDarkly/LaunchDarklyFeatureFlags'
import LaunchDarklySingleton from '../../lib/launchDarkly/LaunchDarkly'
import { SecondaryPlayerSingleton } from '../../lib/SecondaryPlayer'
import { sendMetric } from '../../lib/analytics/Analytics'
import { EVENTS } from '../../lib/analytics/types'
import { SubscriptionBuilder, SubscriptionSources } from '../../util/SubscriptionBuilder'
import BasePlayerControls from '../../components/player/PlayerControls/BasePlayerControls'
import TVPlatform from '../../lib/tv-platform'
import {
  ErrorModalAction,
  ErrorModalHook,
  useErrorModal,
} from '../../widgets/Modals/errorModal/hooks/useErrorModal'
import ModalManager, {
  CloseReason,
  ModalManagerEvent,
  ModalManagerEventTypes,
  ModalTypes,
} from '../../lib/ModalManager'
import { StreamLoader } from './StreamLoader'
import { StreamAuthorizationType, StreamRequest } from './StreamLoader/request'
import { openErrorModal } from '../../widgets/Modals/errorModal/ErrorModal'
import { openPlayerLoader } from '../../widgets/Modals/playerLoader/PlayerLoader'
import { CoreVideoPlayer } from '../../player/CoreVideoPlayer/CoreVideoPlayer'
import Announcer from '../../lib/tts/Announcer'
import { isEmpty } from 'lodash'
import { getLemonade } from '../../store/PlayerStore/actions/lemonade'
import { useRouterBackDisabled } from '../../widgets/Modals/activation/hooks/useRouterBackDisabled'
import { AppStateManager } from '../../lib/AppStateManager'
import AuthenticationSingleton from '../../authentication/Authentication'

export enum TEMP_PASS_REDIRECT {
  TEMP_PASS_MVPD = 'tempPassMvpd',
  TEMP_PASS_NBC_ACCOUNT = 'tempPassNBC',
  TEMP_PASS_CONCLUDED = 'tempPassConcluded',
}

const LAST_KNOWN_DEVICE_CC_STYLE_HASH = 'lastKnownDeviceCCStyleHash'

// @ts-expect-error TS(2417): Class static side 'typeof BasePlayer' incorrectly ... Remove this comment to see the full error message
export default class BasePlayer extends Lightning.Component<
  Lightning.Component.TemplateSpecLoose,
  RouterPage
> {
  _log_tag = 'Player'
  _storage = StorageFactorySingleton.get()
  _errorModalHook: ErrorModalHook

  // seconds watched
  _programOrder = 1
  _stream_type = CONTENT_TYPE.LINEAR
  _isCCAvailable = false
  _isBuffering = false
  _hasError = false
  _forceExit = true
  _liveGuideEnabled = false

  static INACTIVE_TIME_OUT = 4 * 60 * 60 * 1000
  static INACTIVE_MODAL_CLOSE_TIME_OUT = 60 * 1000
  static INITIAL_CONTROLS_TIMEOUT = 10000

  _adIndicator: any
  _analyticsDelegate: any
  _mediaOptionsPanel: MediaOptionsPanel
  _cancelledActivation = false
  _captions: PlayerCaptions
  _controls: BasePlayerControls
  _errorModal: any
  _inActive: any
  _noBtn: any
  _params: any
  _player?: CoreVideoPlayer | null
  _inactiveBoundary?: number
  _qosPanel: any
  _pauseAdOverlay: any
  _storeSubscription: any
  _subscription?: Subscription
  _yesBtn: any
  authtype: StreamAuthorizationType
  initialTempPassRequest: boolean | undefined
  pid: any

  static override _template(overrides: any) {
    return templateDeepMerge(
      {
        w: SCREEN_SIZE.width,
        h: SCREEN_SIZE.height,
        Image: {
          alpha: 0.6,
        },
        QOSPanel: {
          type: PlayerQOSPanel,
          alpha: 0,
        },
        Controls: {
          alpha: 0,
          zIndex: 2,
        },
        Captions: {
          type: PlayerCaptions,
          x: 548,
          w: 1372,
          y: 855,
        },
        MediaOptionsPanel: {
          type: MediaOptionsPanel,
        },
        InActive: {
          w: SCREEN_SIZE.width,
          h: SCREEN_SIZE.height,
          rect: true,
          color: COLORS.dark,
          alpha: 0,
          InActiveMsg: {
            y: 355,
            w: 474,
            x: (w: any) => w / 2,
            mountX: 0.5,
            text: {
              fontSize: 48,
              fontFace: FONT_FACE.light,
              lineHeight: 70,
              textColor: COLORS.white,
              verticalAlign: VERTICAL_ALIGN.middle,
              maxLines: 2,
              textAlign: TEXT_ALIGN.center,
              text: Language.translate('still_watching_live_stream'),
            },
          },
          YesBtn: {
            type: ModalButton,
            y: 586,
            x: (w: any) => w / 2,
            label: Language.translate('yes').toUpperCase(),
          },
          NoBtn: {
            type: ModalButton,
            y: 661,
            x: (w: any) => w / 2,
            label: Language.translate('No').toUpperCase(),
          },
        },
        AdIndicator: {
          alpha: 0,
          flex: {},
          rect: true,
          color: COLORS.black6,
          x: 53,
          y: 58,
          h: 60,
          zIndex: 100,
          AdIndicatorText: {
            flexItem: {
              marginTop: 10,
              marginBottom: -4,
              marginLeft: 20,
              marginRight: 20,
            },
            text: {
              fontFace: FONT_FACE.regular,
              fontSize: 30,
              verticalAlign: VERTICAL_ALIGN.middle,
              textAlign: TEXT_ALIGN.center,
              textColor: COLORS.white,
            },
          },
        },
        PauseAdOverlay: {
          w: SCREEN_SIZE.width,
          h: SCREEN_SIZE.height,
          x: 0,
          y: 0,
          alpha: 0,
          rect: true,
          colorTop: COLORS.green,
          colorBottom: COLORS.red1,
          zIndex: 0,
        },
      },
      overrides
    )
  }

  override set params(params: any) {
    this._params = params
  }

  set cancelledActivation(value) {
    this._cancelledActivation = value
  }

  get cancelledActivation() {
    return this._cancelledActivation
  }

  static override _states(): any[] {
    return [PlayerInactiveStateFactory(this), CorePlayerStateFactory(this)]
  }

  override _enable(): void {
    SecondaryPlayerSingleton.destroy()
  }

  override _active(): void {
    this.stage.setClearColor(COLORS.transparent)
  }

  override _init() {
    this.stage.setClearColor(COLORS.transparent)
    openPlayerLoader()
    this._player = PlayerFactorySingleton.player(PLAYER_TYPE.MAIN)
    this._errorModalHook = useErrorModal(this._onErrorModalEvent)

    this._inActive = this.tag('InActive')
    this._yesBtn = this.tag('YesBtn')
    this._noBtn = this.tag('NoBtn')
    this._errorModal = this.tag('ErrorModal')
    this._controls = this.tag('Controls')
    this._captions = this.tag('Captions')
    this._mediaOptionsPanel = this.tag('MediaOptionsPanel')
    this._qosPanel = this.tag('QOSPanel')
    this._adIndicator = this.tag('AdIndicator')
  }

  override _attach() {
    if (TVPlatform.getForceGC()) this.stage.gc()
    this._subscription = new SubscriptionBuilder()
      .with(
        // We have to use bind(this), because when defining
        // the callback as a class property, we lose access to super
        { type: SubscriptionSources.PLAYER, handler: this._playerEventsHandler.bind(this) },
        {
          type: SubscriptionSources.TV_PLATFORM,
          event: PlatformSubscriptionType.CC,
          handler: this._onPlatformEvent,
        },
        { type: SubscriptionSources.MODAL, handler: this._onModalEvent.bind(this) },
        SubscriptionSources.PLAYER_STORE
      )
      .subscribe(this._onStoreEvent.bind(this))
    this._qosPanel.alpha = DebugControllerSingleton.qosEnabled
  }

  override _detach() {
    this.stage.setClearColor(COLORS.dark)
    this._subscription?.unsubscribe()
    this._subscription = undefined
    this._closePlayer()
    this._errorModalHook.unsubscribe()

    // If the route was changed using Epg, then does not clear the state because some times the load for the new route is called before this detach method
    if (
      ModalManager.getActiveModal() !== ModalTypes.PLAYER_LOADER &&
      this._getState() !== PlayerStates.Epg
    ) {
      PlayerStoreSingleton.dispatch(clearState())
    }
  }

  _closePlayer() {
    if (this._player) {
      this._player?.clearPreviousSession()
      this._player?.close()
      this._player = null
      PlayerFactorySingleton.clearPlayer(PLAYER_TYPE.MAIN)
    }
  }

  _onPlatformEvent = (enabled: boolean, ccStyles?: CCStyleEvent) => {
    syncCcSettings(enabled)
    this._mediaOptionsPanel?._createLanguageList()
    this._player?._updateSubtitles?.()
    if (ccStyles) {
      // Just storing the hash because the values are stored individually
      // We just need a way to check if we set a new value from the device
      // while the TV was off/inactive
      const lastKnownDeviceHash = Storage.get(LAST_KNOWN_DEVICE_CC_STYLE_HASH)
      const currentHash = createHash(JSON.stringify(ccStyles))
      if (currentHash !== lastKnownDeviceHash) {
        Storage.set(LAST_KNOWN_DEVICE_CC_STYLE_HASH, currentHash)
        this._captions?.syncCCSettings(ccStyles)
      } else {
        this._captions?.syncCCSettings()
      }
    } else {
      this._captions?.syncCCSettings()
    }
  }

  _onStoreEvent(data: any) {
    // override me
  }

  _onModalEvent(e: ModalManagerEvent) {
    if (e.event === ModalManagerEventTypes.UPDATE) return
    this._player?.setMute(e.event === ModalManagerEventTypes.OPEN)
    if (e.event === ModalManagerEventTypes.CLOSE && e.payload === CloseReason.COMPLETED) {
      const { stream, program } = PlayerStoreSingleton
      openPlayerLoader(stream, program)
      this._load()
    }
  }

  _play() {
    this._player?.play()
    this._controls.onPlayAnnounce()
    return true
  }

  _pause() {
    this._player?.pause()
    this._controls.onPauseAnnounce()
    return false
  }

  _playOrPause() {
    if (this._player?.isPlaying()) {
      return this._pause()
    } else {
      return this._play()
    }
  }

  /**
   * MediaPlay key handler
   * @private
   */
  _handleMediaPlay() {
    this._play()
  }

  /**
   * MediaPause key handler
   * @private
   */
  _handleMediaPause() {
    this._pause()
  }

  /**
   * MediaStop key handler
   * @private
   */
  _handleMediaStop() {
    this._pause()
  }

  /**
   * MediaPlayPause key handler
   * @private
   */
  _handleMediaPlayPause() {
    this._playOrPause()
  }

  // #region Player events
  _playerEventsHandler(event: any) {
    if (event instanceof PlayerStatusEvent) {
      this._onPlayerStatusChange(event.status)
    } else if (event instanceof BufferStartEvent) {
      this._onBufferStart()
    } else if (event instanceof BufferEndEvent) {
      this._onBufferEnd()
    } else if (event instanceof FatalErrorEvent) {
      this._setErrorState(PlayerError.UNKNOWN, event._error)
    } else if (event instanceof AdBreakStartEvent) {
      this._onAdBreakStart()
    } else if (event instanceof AdBreakEndEvent) {
      this._onAdBreakEnd()
    } else if (event instanceof AdStartEvent) {
      this._onAdStart(event)
    } else if (event instanceof AdEndEvent) {
      this._onAdEnd()
    } else if (event instanceof SubtitleEvent) {
      this._onCues(event.subtitleData.cues)
      this._onTracks(event.subtitleData.tracks)
    } else if (event instanceof SeekStartEvent) {
      this._onSeekStart()
    } else if (event instanceof SeekEndEvent) {
      this._onSeekEnd()
    } else if (event instanceof AudioTracksEvent) {
      this._onAudioTracks(event.data)
    } else if (event instanceof AudioTrackEvent) {
      this._onAudioTrackChange(event.trackId)
    } else if (event instanceof TimeChangeEvent && this._inactiveBoundary) {
      if (Date.now() > this._inactiveBoundary) this._onPlayerInactive()
    }
  }

  _onPlayerStatusChange(status: any) {
    switch (status) {
      case PlayerStatus.PLAYING:
        ModalManager.close()
        if (this._isBuffering) this._onBufferEnd()
        break
      case PlayerStatus.PAUSED:
        if (this._isBuffering) this._onBufferEnd()
        else if (
          ![
            PlayerStates.LPPlayer_Controls,
            PlayerStates.LPPlayer_Controls_Seeking,
            PlayerStates.Epg,
          ].includes(this._getState() as any)
        )
          this._setState(PlayerStates.LPPlayer)

        break
      case PlayerStatus.READY:
        this._analyticsDelegate?.firePlayerReady()
        break
      case PlayerStatus.LOADING:
        if (
          ModalManager._activeModal === ModalTypes.ACTIVATION &&
          AuthenticationSingleton.isAuthenticated()
        ) {
          ModalManager.close()
        }
        // Loading from media recovery, show buffer screen.
        this._onBufferStart()
        if (this._params?.forceAnnounce) {
          // Fire announcement only once
          Announcer.announce(this._params.forceAnnounce, { notification: true })
          delete this._params.forceAnnounce
        }
        break
      case PlayerStatus.BUFFERING:
      case PlayerStatus.SEEKING:
        // Seeking from recovery, show buffer instead of blank screen.
        this._onBufferStart()
        break
      case PlayerStatus.EXPIRED:
        this._setErrorState(
          PlayerError.EXPIRED,
          new FatalErrorEvent({
            description: 'Expired',
            code: '',
          })
        )
        break
      case PlayerStatus.FINISHED:
        this._onStreamEnd?.()
        break
      default:
        break
    }
  }

  _onSeekStart() {
    this._onBufferStart()
  }

  _onBufferStart() {
    this._isBuffering = true
    const { stream, program } = PlayerStoreSingleton
    openPlayerLoader(stream, program, true)
    setSmooth(this._captions, 'alpha', 0)
  }

  _onSeekEnd() {
    this._onBufferEnd()
    this._player?.play()
  }

  _onBufferEnd() {
    this._isBuffering = false
    setSmooth(this._captions, 'alpha', 1)
  }

  _onAdBreakStart() {
    this._controls.showAdControls()
    this._captions._reset()
  }

  _onAdBreakEnd() {
    this._controls.hideAdControls()
    this._controls.setPlayPauseState()
    this._hideAdIndicator()
    setSmooth(this._captions, 'alpha', 1)
  }

  _onAdStart(event: any) {
    this._updateAdIndicator(event.ad.index, event.adBreak.ads.length)
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  _onAdEnd() {}

  _onCues(activeCues: any) {
    if (!activeCues) {
      return
    }
    if (!this._isCCAvailable && activeCues.length > 0) {
      this._isCCAvailable = true
      this._controls._showCCOption()
      this._captions.alpha = 1
    }
    this._captions._setActiveCues(activeCues)
  }

  _onTracks(tracks: any) {
    if (!tracks || !tracks.length) return

    this._mediaOptionsPanel._createLanguageList(
      tracks.reduce(
        // @ts-expect-error TS(7031): Binding element 'optionValue' implicitly has an 'a... Remove this comment to see the full error message
        (acc: any, { language: optionValue, kind }) =>
          optionValue === 'und'
            ? acc
            : acc.concat({
                optionValue,
                kind,
              }),
        []
      )
    )
  }

  _onAudioTracks(tracks: Track[]) {
    if (!tracks || !tracks.length) {
      this._mediaOptionsPanel.hideAudioSection()
      return
    }
    this._mediaOptionsPanel._createAudioLanguageList(tracks)
  }

  _onAudioTrackChange(trackId: string | number) {
    this._mediaOptionsPanel.currentAudioTrackId = trackId
  }

  _onErrorModalEvent = (event?: ErrorModalAction) => {
    const closeReason =
      event === ErrorModalAction.BACK || event === ErrorModalAction.EXIT
        ? CloseReason.CANCELLED
        : CloseReason.DEFAULT
    ModalManager.close(closeReason)
    switch (event) {
      case ErrorModalAction.RETRY:
        this._load()
        break
      case ErrorModalAction.REDIRECT:
        Router.navigate(ROUTE.live)
        break
      case ErrorModalAction.LIVE_GUIDE:
        this._onErrorModalLiveGuide()
        break
      case ErrorModalAction.BACK:
      case ErrorModalAction.EXIT:
        if (!useRouterBackDisabled().value) Router.back()
        else this._onErrorModalBack()
        break
      default:
        break
    }
  }

  _onErrorModalBack() {}

  _onErrorModalLiveGuide() {}

  _onLoaderExit() {}
  // #endregion

  // #region UI methods
  _updateAdIndicator(currentAdPosition: any, totalAds: any) {
    this._adIndicator.patch({
      alpha: 1,
      AdIndicatorText: {
        text: Language.translate('playing_ad', currentAdPosition + 1, totalAds),
      },
    })
  }

  _hideAdIndicator() {
    this._adIndicator.patch({
      alpha: 0,
      AdIndicatorText: {
        text: '',
      },
    })
  }

  override _handleBack(e: any) {
    this._closeMediaPlayer()
    e?.preventDefault?.()
    e?.stopPropagation?.()
  }

  $trueBack(force = false) {
    if (this._hasError || force) {
      this._closeMediaPlayer(true)
    } else {
      this._setState(PlayerStates.LPPlayer)
    }
  }

  _endMediaSession = () => {
    this._isBuffering = false
    this._player?.close()
    this._analyticsDelegate?.fireSessionEnd()
    if (this._storage.get(STORAGE_KEYS.REFERRING_SHELF)) {
      this._storage.remove(STORAGE_KEYS.REFERRING_SHELF)
    }
    if (this._storage.get(STORAGE_KEYS.SMART_TILE)) {
      this._storage.remove(STORAGE_KEYS.SMART_TILE)
    }
  }

  _closeMediaPlayer(forceExit = false) {
    if (Storage.get(STORAGE_KEYS.REFERRING_SHELF)) {
      Storage.remove(STORAGE_KEYS.REFERRING_SHELF)
    }
    if (Storage.get(STORAGE_KEYS.PLAYLIST_MACHINE_NAME)) {
      Storage.remove(STORAGE_KEYS.PLAYLIST_MACHINE_NAME)
    }
    if (this._storage.get(STORAGE_KEYS.SMART_TILE)) {
      this._storage.remove(STORAGE_KEYS.SMART_TILE)
    }
    // If the menu's not shown (and exit isn't forced), show it first.
    if (this?.widgets?.menu && !this.widgets.menu.visible && !forceExit) {
      this.widgets.menu.visible = true
      this.widgets.menu.onBack = this._endMediaSession
      this.widgets.menu.onEnter = this._endMediaSession
      Router.focusWidget('Menu')
    } else {
      // Otherwise actually close and go back.
      if (Router.isNavigating()) return // Prevent additional navigation backwards if we are already navigating
      this._endMediaSession()
      const prevRoute = Router.getHistory()?.pop()?.hash
      if (prevRoute && prevRoute.includes(PAGE_NAME.replays.toLowerCase())) TVPlatform.historyBack()
      else Router.back()
    }
    if (TVPlatform.getForceGC()) this.stage.gc()
  }

  _updateActiveCues(activeCues: any) {
    if (!this._isCCAvailable) {
      this._isCCAvailable = true
      this._controls._showCCOption()
      this._captions.alpha = 1
    }
    this._captions._setActiveCues(activeCues)
  }

  _setErrorState(errorType: PlayerError, errorEvent?: any) {
    this._hasError = true
    setSmooth(this._captions, 'alpha', 0)
    this._endMediaSession()
    this._analyticsDelegate?.fireSessionEnd()
    this._openErrorModal({ authType: this.authtype, error: { detail: errorType } })
  }

  _setPlayerInActiveTimeout() {
    const ldTimeout =
      LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.liveCheckModal) || 0
    const configTimeout = AppConfigFactorySingleton.config?.live?.stillWatchingTimeout
    const offset = Number(ldTimeout * 1000 || configTimeout || BasePlayer.INACTIVE_TIME_OUT)
    this._inactiveBoundary = Date.now() + offset
  }

  _onPlayerInactive() {
    this._clearPlayerInActiveTimeOut()
    this._setState(PlayerStates.PlayerInActive_Yes)
    sendMetric(EVENTS.MODAL_LOAD, {
      modalName: 'Are you still watching',
      modalType: 'Live Inactivity Check',
    })
  }

  _clearPlayerInActiveTimeOut() {
    this._inactiveBoundary = undefined
  }

  async _startStream(): Promise<void> {
    if (!this._player) return
    if (isEmpty(PlayerStoreSingleton.lemonade))
      await PlayerStoreSingleton.dispatch(getLemonade(PlayerStoreSingleton.stream))
    await this._player.load()
    this._controls.setup()
    this._analyticsDelegate?.fireSessionStart()
    AppStateManager.checkPendingEvents()
    this._setState(PlayerStates.LPPlayer)
  }

  _onStreamEnd() {}

  $audioLanguageUpdated(trackId: number | string): void {
    this._player?.setAudioTrack(trackId)
  }

  _startNewTrackingSession() {
    this._programOrder = 1
    this._analyticsDelegate?.fireSessionInit()
  }

  _disableRouterBack() {
    return false
  }

  async _load() {
    this._startNewTrackingSession()
    try {
      if (!this._params?.preauth) {
        useRouterBackDisabled().set(
          this._controls?.tempPassCtaClicked ? true : this._disableRouterBack()
        )
        const streamLoaderObject = await StreamLoader(
          PlayerStoreSingleton.stream,
          PlayerStoreSingleton.program,
          PlayerStoreSingleton.lemonade,
          this._params?.allowToPlay
        )
        if (!streamLoaderObject) return
        const { authType, initialRequest } = streamLoaderObject
        this.authtype = authType
        this.initialTempPassRequest = initialRequest
      } else {
        this.authtype = this._params.preauth
      }
      await this._startStream()
    } catch (res: any) {
      this._openErrorModal(res)
      this._endMediaSession()
    }
  }

  _openErrorModal(res: StreamRequest) {
    const { stream, program } = PlayerStoreSingleton
    if (!res.error?.handled) openErrorModal(res, stream, program, this._liveGuideEnabled)
  }

  _hideUI() {
    this._controls.alpha = 0
    this._captions.alpha = 0
    this._mediaOptionsPanel._showMediaOptionsMenu(false)
    if (this.widgets?.menu) this.widgets.menu.visible = false
  }

  _resetStream() {}

  // #endregion
}
