import { Subject, filter } from 'rxjs'

export enum ModalTypes {
  UPCOMING = 'upcoming',
  QR_MODAL = 'qrModal',
  PLAYER_ERROR = 'playerError',
  PLAYER_LOADER = 'playerLoader',
  ACTIVATION = 'activation',
  ACTIVATION_TEMPPASS_MVPD = 'activationTempPassMvpd',
  ACTIVATION_TEMPPASS_NBC = 'activationTempPassNbc',
  TEMPPASS_CONCLUDED = 'tempPassConcluded',
  ONBOARDING_MODAL = 'onboardingModal',
  ONBOARDING_ACTIVATION = 'onboardingActivation',
}

export enum CloseReason {
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  DEFAULT = 'default',
}

export enum ModalManagerEventTypes {
  CLOSE = 'close',
  OPEN = 'open',
  UPDATE = 'update',
}

export type ModalManagerEvent = {
  type: ModalTypes
  event: ModalManagerEventTypes
  payload?: any
  focusPage: boolean
}

const createModalManagerEvent = (
  type: ModalTypes,
  event: ModalManagerEventTypes,
  payload?: any,
  focusPage = true
): ModalManagerEvent => ({ event, type, payload, focusPage })

class ModalManagerClass {
  _subject = new Subject()
  _activeModal?: ModalTypes

  subscribe(type: ModalTypes, callback: (evt: ModalManagerEvent) => void) {
    return this._subject.pipe(filter((e: any) => e?.type === type)).subscribe(callback)
  }

  close(closeReason = CloseReason.DEFAULT, focusPage = true) {
    if (!this._activeModal) return
    const previousActiveModal = this._activeModal
    this._activeModal = undefined
    this._subject.next(
      createModalManagerEvent(
        previousActiveModal,
        ModalManagerEventTypes.CLOSE,
        closeReason,
        focusPage
      )
    )
  }

  open(type: ModalTypes, data?: any, focusPage = true) {
    if (this._activeModal) this.close(CloseReason.CANCELLED, focusPage)
    this._activeModal = type
    this._subject.next(createModalManagerEvent(type, ModalManagerEventTypes.OPEN, data))
  }

  update(type: ModalTypes, data?: any, force = false) {
    if (!this._activeModal && !force) return
    this._subject.next(createModalManagerEvent(type, ModalManagerEventTypes.UPDATE, data))
  }

  getActiveModal() {
    return this._activeModal
  }
}

const ModalManager = new ModalManagerClass()

export const isBlockingModalOpen = () =>
  [
    ModalTypes.ACTIVATION,
    ModalTypes.ACTIVATION_TEMPPASS_MVPD,
    ModalTypes.ACTIVATION_TEMPPASS_NBC,
    ModalTypes.ACTIVATION_TEMPPASS_NBC,
    ModalTypes.TEMPPASS_CONCLUDED,
    ModalTypes.PLAYER_ERROR,
    ModalTypes.ONBOARDING_MODAL,
    ModalTypes.ONBOARDING_ACTIVATION,
  ].includes(ModalManager._activeModal as ModalTypes)

export default ModalManager
