import Announce from './Announce'
import { Registry } from '@lightningjs/sdk'

const estimateSpeechTime = (
  text: string,
  wordsPerMinute = 160,
  punctuationPause = 500,
  sentencePause = 800,
  lineBreakPause = 800,
  emailCharTime = 800 // Time per email character (in milliseconds)
): number => {
  const trimmed = text.trim()
  // Split the text into words, ignoring empty strings
  const wordCount = trimmed.split(/\s+/).filter(Boolean).length
  // Count the number of sentences (periods, exclamation marks, question marks)
  const sentenceEndings = (trimmed.match(/[.!?]/g) || []).length
  // Count the number of commas and similar punctuation
  const punctuationCount = (trimmed.match(/[,;:]/g) || []).length
  // Count the number of line breaks
  const lineBreaks = trimmed.split(/\n+/).length - 1
  // Find email addresses
  const emailMatches: Array<string> =
    trimmed.match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g) || []
  // Count the number of characters in email addresses
  const emailCharCount = emailMatches.length
    ? emailMatches.reduce((sum: number, email: string) => sum + email.length, 0)
    : 0
  // Time for words (in milliseconds)
  const speechTime = Math.ceil((wordCount / wordsPerMinute) * 60) * 1000
  // Time for pauses (sentences, punctuation, line breaks)
  const totalPauseTime =
    sentenceEndings * sentencePause +
    punctuationCount * punctuationPause +
    lineBreaks * lineBreakPause
  // Time for spelling out email characters
  const emailTime = emailCharCount * emailCharTime
  return speechTime + totalPauseTime + emailTime
}

export default class VizioAnnounce extends Announce {
  async speak(): Promise<void> {
    return new Promise<void>((resolve) => {
      window.VIZIO.Chromevox?.play(this.phrase)
      Registry.setTimeout(() => resolve(), estimateSpeechTime(this.phrase))
    })
  }
}
