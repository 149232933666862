import { AnnouncementType } from '../types'

export default abstract class Announce {
  protected _phrase: string
  protected _notification = false

  constructor(toSpeak: AnnouncementType, notification = false) {
    this._notification = notification
    const seriesArray = Array.isArray(toSpeak) ? toSpeak : [toSpeak]
    this._phrase = seriesArray.reduce(this.reduceSeries, '')
  }

  get phrase(): string {
    return this._phrase
  }

  get notification(): boolean {
    return this._notification
  }

  protected reduceSeries = (acc: string, curr: AnnouncementType): string => {
    let next: string
    if (Array.isArray(curr)) {
      next = curr.reduce(this.reduceSeries, acc)
    } else if (typeof curr === 'function') {
      const funcExecuted = curr()
      //@ts-expect-error TS2349: this expression is not callable
      next = (Array.isArray(funcExecuted) ? funcExecuted : [funcExecuted]).reduce(
        this.reduceSeries,
        acc
      )
    } else {
      next = acc += (acc.length ? ' ' : '') + curr + '\n'
    }
    return next
  }

  abstract speak(): Promise<void>
}
