import Announce from './Announces/Announce'
import TVPlatform from '../tv-platform'
import { AnnouncementType } from './types'

class SpeechController {
  private _active = false
  private _voiceOutDisabled = false
  private _announces: Announce[] = []
  private _tts = TVPlatform.tts()

  get active(): boolean {
    return this._active
  }

  get voiceOutDisabled(): boolean {
    return this._voiceOutDisabled
  }

  append(toSpeak: AnnouncementType): void {
    this._announces.push(this._tts.speak(toSpeak))
  }

  cancel(): void {
    this._announces = []
    this._tts.cancel()
    this._active = false
    this._voiceOutDisabled = false
  }

  async speak(series: AnnouncementType, notification = false): Promise<void> {
    this._active = true
    this._announces.push(this._tts.speak(series, notification))
    await this._speakSeries()
  }

  private async _speakSeries(): Promise<void> {
    while (this._announces.length) {
      const series = this._announces.shift()
      if (!series) continue
      this._voiceOutDisabled = series.notification
      await series.speak()
    }
    this._voiceOutDisabled = false
    this._active = false
  }
}

export default new SpeechController()
