import { isBionicStream } from '../../../api/Live'
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore'
import { getLemonade } from '../../../store/PlayerStore/actions/lemonade'
import { authenticate } from './actions/authenticate'
import { linearSleFerAuthorize, vodAuthorize } from './actions/authorize'
import { geo } from './actions/geo'
import { missingDataError } from './error'
import { StreamAuthorizationType, StreamRequest, createLoader } from './request'

const vodStreamLoader = (stream: any, program: any, lemonade: any, bypass: boolean) => {
  return createLoader([authenticate, geo, vodAuthorize(bypass)], stream, program, lemonade)
}

const linearSleFerLoader = (stream: any, program: any, lemonade: any, isPreCheck = false) => {
  return createLoader(
    [authenticate, geo, linearSleFerAuthorize],
    stream,
    program,
    lemonade,
    isPreCheck
  )
}

const nbcNewsLoader = (stream: any): Promise<StreamRequest> =>
  PlayerStoreSingleton.dispatch(getLemonade(stream))
    .then(() => ({ authType: StreamAuthorizationType.UNLOCKED }))
    .catch(() =>
      Promise.reject({ authType: StreamAuthorizationType.UNLOCKED, error: missingDataError('') })
    )

export const StreamLoader = (
  stream: any,
  program: any,
  lemonade: any,
  bypass = false,
  isPreCheck = false
) => {
  // Some live stream will also have mpxguid so adding additional check
  if (stream?.mpxGuid && !stream?.stationId) {
    return vodStreamLoader(stream, program, lemonade, bypass)
  }
  if (isBionicStream(stream?.channelId)) {
    return linearSleFerLoader(stream, program, lemonade, isPreCheck)
  }
  return nbcNewsLoader(stream)
}
