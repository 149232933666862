enum LaunchDarklyFeatureFlags {
  removePersonalization = 'remove-personalization',
  skipTimer = 'skip-timer',
  sleLiveGuide = 'sle-live-guide',
  sleLiveGuideShelf = 'sle-live-guide-shelf',
  enableFERContent = 'enable-fer-content',
  enableFERSearch = 'enable-fer-search',
  tempPass = 'temp-pass',
  externalAppLinking = 'temp-pass-modal-cta',
  enablePauseAdsRefresh = 'pause-ad-enable',
  pauseAdsRefreshCount = 'pause-ad-refresh',
  liveCheckModal = 'live-check-modal',
  enableMarketingModule = 'enable-marketing-module',
  enableSleBinge = 'enable-sle-binge',
  sleBingeTimer = 'sle-binge-timer',
  marketingModulePreview = 'marketing-module-preview',
  marketingModuleAudio = 'marketing-module-audio',
  livePlayerWatchDuration = 'live-player-watch-duration',
  liveGuide = 'live-guide',
  newPlayerTest = 'new-player-test',
  ctvOnboarding = 'ctv-onboarding',
}
export default LaunchDarklyFeatureFlags
