import { Language } from '@lightningjs/sdk'
import { getMpid } from '../../helpers'
import AppConfigFactorySingleton from '../../config/AppConfigFactory'

/**
 * Determines if the data download should include profile data or not.
 * By default if no fetch type is passed, we use APP_DATA_ONLY.
 * If cross device is in scope, pass in APP_DATA_AND_SYNC_PROFILE
 */
enum FetchType {
  'APP_DATA_ONLY' = 'APP_DATA_ONLY',
  'APP_DATA_AND_PROFILE' = 'APP_DATA_AND_PROFILE',
  'APP_DATA_AND_SYNC_PROFILE' = 'APP_DATA_AND_SYNC_PROFILE',
}

const getBaseHeaders = (consentString = '') => ({
  'OT-CDN-Location': 'cdn.cookielaw.org',
  'OT-App-Id': AppConfigFactorySingleton.config.one_trust_id,
  'OT-SDK-Version': AppConfigFactorySingleton.config.one_trust_version,
  'OT-Device-Type': 'ctv',
  'OT-Consent-String': consentString,
  'OT-Identifier': getMpid(),
  'OT-Sync-Profile-Auth': '',
  'OT-Fetch-Type': FetchType.APP_DATA_AND_SYNC_PROFILE,
  'OT-Language': (Language.get() || 'EN').toLowerCase(), // en or es
  'OT-Country-Code': 'US',
  'OT-Region-Code': '',
  'OT-Tcf-Eu2v2-Consent-String': consentString,
})

export const getSaveApiHeaders = (consentString = '') =>
  new Headers({
    ...getBaseHeaders(consentString),
    'Content-Type': 'application/json',
    'OT-Identifier-Type': '',
    'OT-GPP-String': '',
  })

export const getBannerPreferenceHeaders = (consentString = '') =>
  new Headers({
    ...getBaseHeaders(consentString),
    'OT-Force-Fetch': 'false',
  })
